import { Contact, Education, Header, Home, Projects, Services,  Footer } from './components';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Education />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
