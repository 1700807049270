import project3Img from "../../assets/project3.jpg";
import realEstateImg from "../../assets/realEstate.jpg";

import aboutImage3 from "../../assets/aboutImage3.jpg"; // Importujte sliku za novi projekat
 // Importujte sliku za novi projekat

export const Projects = () => {
  const projectsData = [
    {
      title: "E-Commerce Application Demo",
      description:
        "A fully functional full-stack e-commerce application built from scratch, featuring a modern frontend, secure backend, and integrated payment system.",
      link: "https://main-ebookbookcode.netlify.app/",
      image: project3Img,
    },
    {
      title: "Beauty Salon Web Application",
      description:
        "The Beauty salon application offers a fully interactive experience for users looking to schedule appointments and learn about salon services.",
      link: "https://beautywandolly.netlify.app/",
      image: aboutImage3,
    },
    {
      title: "Real Estate Agency",
      description:
        "A modern real estate agency website showcasing properties with advanced search and filter features, along with a sleek user interface.",
      link: "https://dreamy-bublanina-b133ac.netlify.app/",
      image: realEstateImg,
    },
  ];
  

  return (
    <section
      id="projects"
      className="bg-black text-white py-16 pb-8 scroll-margin-top-24 md:scroll-margin-top-24 lg:scroll-margin-top-24 services-adjustment"
    >
      <div className="container mx-auto px-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">
          Projects
        </h2>
        {/* Postavi grid koji se prilagođava broju projekata */}
        <div
          className={`grid gap-8 ${
            projectsData.length === 1
              ? "grid-cols-1 place-items-center" // Centrirano kad je jedan projekat
              : "grid-cols-1 md:grid-cols-2 lg:grid-cols-3" // Više kolona kad je više projekata
          }`}
        >
          {projectsData.map((project, index) => (
            <a
              key={index}
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-800 p-8 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 border-2 border-teal-500 hover:shadow-teal-500 max-w-md w-full"
            >
              <img
                src={project.image}
                alt={project.title}
                className="rounded-lg mb-4 w-full h-48 object-cover"
                loading="lazy"
              />
              <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
              <p className="text-gray-400">{project.description}</p>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};
