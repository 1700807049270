export const Education = () => {
  const educationData = [
    {
      year: "2022",
      title: "IT Academy - Java Backend Course",
      description: "Completed a comprehensive Java backend development course at IT Academy, covering Java, Hibernate, and RESTful web services.",
    },
    {
      year: "2023",
      title: "Master Spring, Spring Boot, React & JavaScript (in28minutes)",
      description: "Participated in an advanced masterclass by in28minutes, covering Spring, Spring Boot, React, and modern JavaScript, focusing on building full-stack web applications.",
    }, 
 
  
     {
      year: "2024",
      title: "Bachelor's Degree in Information Technology",
      description: "Graduated from Singidunum University with a degree in Information Technology, focusing on software engineering, databases, and web development.",
    },
  ];

  return (
    <section id="education" className="bg-black text-white py-12 pt-16">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Education & Training</h2>
        <div className="relative">
          {/* Centralna linija */}
          <div className="absolute h-full border-2 border-teal-400 left-1/2 transform -translate-x-1/2"></div>

          {educationData.map((item, index) => (
            <div key={index} className={`mb-8 flex ${index % 2 === 0 ? 'justify-end' : 'justify-start'} items-center w-full relative`}>
              <div 
                className={`bg-gray-800 p-6 rounded-xl shadow-lg border-2 border-teal-400 w-full md:w-1/3 relative ${index % 2 === 0 ? 'ml-12' : 'mr-12'} ${index % 2 === 0 ? 'mr-auto' : 'ml-auto'} text-center transform transition-transform duration-300 hover:scale-105 hover:border-b-4 hover:border-teal-500 hover:shadow-lg hover:shadow-teal-500`}
              >
                <h3 className="text-lg font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-400 mb-4 text-sm">{item.description}</p>
                <span className="text-teal-400 absolute top-0 -mt-5 text-lg left-1/2 transform -translate-x-1/2 bg-black px-3 py-1">{item.year}</span>
              </div>
              {/* Tačka na liniji */}
              <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 bg-teal-400 h-4 w-4 rounded-full"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
