export const Footer = () => {
    return (
      <footer className="bg-black py-6 fixed bottom-0 left-0 w-full shadow-lg">
        <div className="container mx-auto flex justify-between items-center px-6">
          <p className="text-white">© 2024 Milan Govedarica. All rights reserved.</p>
          <div className="flex space-x-4">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-teal-400 transition-colors duration-300">
              <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-10.15 20h-3v-10h3v10zm-1.5-11.5c-.96 0-1.75-.79-1.75-1.75s.79-1.75 1.75-1.75 1.75.79 1.75 1.75-.79 1.75-1.75 1.75zm13.65 11.5h-3v-5.5c0-1.1-.9-2-2-2s-2 .9-2 2v5.5h-3v-10h3v1.4c.76-1.14 2.1-1.4 3.1-1.4 2.2 0 4 1.8 4 4v6z"/>
              </svg>
            </a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="text-white hover:text-teal-400 transition-colors duration-300">
              <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M12 .5c-6.6 0-12 5.4-12 12 0 5.3 3.4 9.8 8.2 11.4.6.1.8-.3.8-.6v-2c-3.3.7-4-1.4-4-1.4-.5-1.2-1.2-1.5-1.2-1.5-1-.7 0-.7 0-.7 1.2 0 1.8 1.3 1.8 1.3 1 .2 1.7-.8 1.7-.8 1-.2.3-2.5.3-2.5-.7-.1-1.2-.3-1.8-.5-1.8-.8-3.7-3.4-3.7-7.5 0-1.5.6-2.9 1.5-4-.2-.3-.7-1.2 0-2.4 0 0 1.2 0 2.5 1.5.8-.2 1.7-.3 2.6-.3s1.8.1 2.6.3c1.2-1.5 2.5-1.5 2.5-1.5.7 1.3.2 2.2 0 2.4.9 1.1 1.5 2.5 1.5 4 0 4-2 6.7-3.7 7.5-.5.2-1.1.4-1.8.5-.2 1-.5 1.8-.8 2.2.7.2 1.3.5 2 .5 6.5 0 11.8-5.4 11.8-12s-5.4-12-12-12z"/>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    );
  };
  