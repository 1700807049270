export const Services = () => {
  const servicesData = [
    { 
      title: "Web Development", 
      description: "Building modern, responsive websites using cutting-edge technologies such as HTML, CSS, JavaScript, React, and Tailwind CSS. We focus on delivering fast, scalable, and SEO-friendly web applications.", 
      icon: "🌐" 
    },
    { 
      title: "UI/UX Design", 
      description: "Creating intuitive and visually appealing user interfaces that ensure a seamless user experience. We use design tools like Figma and Adobe XD to bring ideas to life with user-centered design principles.", 
      icon: "🎨" 
    },
    { 
      title: "SEO Optimization", 
      description: "Enhancing your website's visibility on search engines through advanced SEO techniques. We ensure your content is easily discoverable, leading to higher organic traffic and better search rankings.", 
      icon: "🚀" 
    },
    { 
      title: "Backend Development ", 
      description: "Building robust and scalable backend systems using Java, Spring Boot, and Hibernate. We specialize in developing RESTful APIs, database management, and secure, high-performance applications.", 
      icon: "⚙️" 
    },
  ];

  return (
    <section id="services" className="bg-black text-white py-16 pb-8 scroll-margin-top-16 md:scroll-margin-top-24 lg:scroll-margin-top-24 services-adjustment">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Services</h2>
        {/* Layout sa 4 kolone za velike ekrane */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4 justify-center">
          {servicesData.map((service, index) => (
            <div 
              key={index} 
              className="bg-gray-800 p-10 max-w-md mx-auto rounded-lg shadow-lg text-center transform transition-transform duration-300 hover:scale-105 border-2 border-teal-500 hover:shadow-teal-500">
              <div className="text-5xl mb-6">{service.icon}</div>
              <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-400">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
