import { useState, useEffect } from "react";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("#home");

 

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setMenuOpen(false); // Zatvori meni kada se klikne na sekciju
  };

  return (
    <>
      <div className="pt-16"></div>
      
      <header className="fixed top-0 left-0 w-full bg-black shadow-lg z-50 border-b border-white">
        <nav className="container mx-auto flex justify-between items-center py-4 px-6">
          <a href="/" className="flex items-center group">
            <span className="text-2xl font-bold text-white group-hover:text-teal-400 transition-colors duration-300">
              Milan <span className="text-teal-400 relative">
                Govedarica
                <span className="absolute inset-0 rounded-full bg-teal-400 opacity-50 blur-lg"></span>
              </span>
            </span>
          </a>
          <div className="hidden md:flex items-center space-x-8">
            <a 
              href="#home" 
              className={`text-lg text-white hover:text-teal-400 transition-colors duration-300 ${activeSection === '#home' ? 'border-b-2 border-teal-400' : ''}`}
              onClick={() => handleSectionClick("#home")}
            >
              Home
            </a>
            <a 
              href="#education" 
              className={`text-lg text-white hover:text-teal-400 transition-colors duration-300 ${activeSection === '#education' ? 'border-b-2 border-teal-400' : ''}`}
              onClick={() => handleSectionClick("#education")}
            >
              Education
            </a>
            <a 
              href="#services" 
              className={`text-lg text-white hover:text-teal-400 transition-colors duration-300 ${activeSection === '#services' ? 'border-b-2 border-teal-400' : ''}`}
              onClick={() => handleSectionClick("#services")}
            >
              Services
            </a>
            <a 
              href="#projects" 
              className={`text-lg text-white hover:text-teal-400 transition-colors duration-300 ${activeSection === '#projects' ? 'border-b-2 border-teal-400' : ''}`}
              onClick={() => handleSectionClick("#projects")}
            >
              Projects
            </a>
       
            <a 
              href="#contact" 
              className={`text-lg text-white hover:text-teal-400 transition-colors duration-300 ${activeSection === '#contact' ? 'border-b-2 border-teal-400' : ''}`}
              onClick={() => handleSectionClick("#contact")}
            >
              Contact
            </a>
          </div>

          <div className="md:hidden flex items-center">
            <button onClick={() => setMenuOpen(!menuOpen)} className="text-white focus:outline-none">
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </nav>

        {menuOpen && (
          <div className="md:hidden bg-black px-6 py-4">
            <a 
              href="#home" 
              className="block text-lg text-white hover:text-teal-400 transition-colors duration-300"
              onClick={() => handleSectionClick("#home")}
            >
              Home
            </a>
            <a 
              href="#education" 
              className="block text-lg text-white hover:text-teal-400 transition-colors duration-300 mt-2"
              onClick={() => handleSectionClick("#education")}
            >
              Education
            </a>
            <a 
              href="#services" 
              className="block text-lg text-white hover:text-teal-400 transition-colors duration-300 mt-2"
              onClick={() => handleSectionClick("#services")}
            >
              Services
            </a>
            <a 
              href="#projects" 
              className="block text-lg text-white hover:text-teal-400 transition-colors duration-300 mt-2"
              onClick={() => handleSectionClick("#projects")}
            >
              Projects
            </a>
            <a 
              href="#testimonials" 
              className="block text-lg text-white hover:text-teal-400 transition-colors duration-300 mt-2"
              onClick={() => handleSectionClick("#testimonials")}
            >
              Testimonials
            </a>
            <a 
              href="#contact" 
              className="block text-lg text-white hover:text-teal-400 transition-colors duration-300 mt-2"
              onClick={() => handleSectionClick("#contact")}
            >
              Contact
            </a>
          </div>
        )}
      </header>
    </>
  );
};
